import DevicesIcon from '@mui/icons-material/Devices';
import AppsIcon from '@mui/icons-material/Apps';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { COLOR_BACKGROUND_DARK, COLOR_LIGHT, LINK_WHATSAPP } from '../../config';
import QuemSomosImg from '../../images/QuemSomos.png'
import ServicosImg from '../../images/Servicos.png'
import ComoFuncionaImg from '../../images/ComoFunciona.png'
import ContatoImg from '../../images/Contato.png'
import { Card } from '../Card';
import { RiFileExcel2Line } from 'react-icons/ri';
import { TbDeviceMobilePlus } from 'react-icons/tb';
import { TfiWorld } from 'react-icons/tfi';
import { MdOutlineScreenSearchDesktop } from 'react-icons/md';
import { Button } from '../Button/ButtonElements';
import { FaWhatsapp } from "react-icons/fa";


const styleImgTitle: any = { marginRight: 12, fontSize: 26 };

export const quemSomos = {
    id: 'quemSomos', imgStart: true, alt: 'Quem Somos', lightBg: true, lightText: false,
    img: ServicosImg,
    topLine:
        <>
            <BadgeOutlinedIcon style={{ ...styleImgTitle, color: COLOR_BACKGROUND_DARK }} />
            Quem Somos
        </>
    ,
    description:
        <div>
            <p>
                Nossa empresa é formada por profissionais da tecnologia com anos de experiência no mercado e apaixonados por resolver problemas de forma criativa e simples
            </p>
            <br />
        </div>,
};


export const servicos = {
    id: 'servicos', imgStart: false, alt: 'Serviços', lightBg: false, lightText: true,
    img: QuemSomosImg,
    topLine:
        <>
            <DevicesIcon style={{ ...styleImgTitle, color: COLOR_LIGHT }} />
            Serviços
        </>
    ,
    description:
        <div>
            <Card
                icon={<TbDeviceMobilePlus />}
                title={'Aplicativos'}
                description={'Controle processos de forma dinâmica com acesso de qualquer lugar'}
            />
            <Card
                icon={<TfiWorld />}
                title={'Websites'}
                description={'Tenha uma página profissional para divulgar o seu negócio'}
            />
            <Card
                icon={<MdOutlineScreenSearchDesktop />}
                title={'Desktop'}
                description={'Soluções robustas para acessar através do seu computador ou notebook'}
            />
            <Card
                icon={<RiFileExcel2Line />}
                title={'Excel'}
                description={'Criação ou aprimoramento de planilhas utilizando técnicas avançadas de Excel'}
            />
        </div>
};

export const comoFunciona = {
    id: 'comoFunciona', imgStart: true, alt: 'Como Funciona', lightBg: true, lightText: false,
    img: ComoFuncionaImg,
    topLine:
        <>
            <AppsIcon style={{ ...styleImgTitle, color: COLOR_BACKGROUND_DARK }} />
            Como Funciona
        </>
    ,
    description:
        <div>
            <p>1. Após o contato, agendamos uma conversa para entender as suas solicitações. Vamos buscar a melhor forma de te atender</p><br />
            <p>2. Elaboramos uma análise do que é necessário, determinamos o prazo e submetemos um orçamento para sua aprovação</p><br />
            <p>3. Desenvolvemos a solução combinada e ela é entregue para que você possa começar a utilizar</p><br />
            <p>4. Durante algumas semanas fazemos o acompanhamento para garantir sua satisfação e efetuar pequenas alterações caso necessário</p><br />
        </div>
};

export const contato = {
    id: 'contato', imgStart: false, alt: 'Contato', lightBg: false, lightText: true,
    img: ContatoImg,
    topLine:
        <>
            <DevicesIcon style={{ ...styleImgTitle, color: COLOR_LIGHT }} />
            Contato
        </>
    ,
    description:
        <div>
            <p>Entre em contato e faça já seu orçamento</p><br />
            <Button href={LINK_WHATSAPP} target="_black" arial-label="WhatsApp">
                <FaWhatsapp style={{ fontSize: '28px', marginRight: '10px' }} />
                FAZER ORÇAMENTO
            </Button>
        </div>
};