import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { FooterContainer, FooterWrapper, LogoImg, SocialIconLink, SocialIcons, SocialLogo, SocialMedia, SocialMediaWrap, WebSiteRights } from "./FooterElements";
import { animateScroll as scroll } from 'react-scroll';
import { LINK_INSTAGRAM, COPYRIGHTS, LINK_WHATSAPP } from "../../config";
import Logo from '../../images/logo.png';

const Footer = () => {
    const toggleTop = () => {
        scroll.scrollToTop();
    }

    return (
        <FooterContainer>
            <FooterWrapper>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo >
                            <LogoImg src={Logo} onClick={toggleTop} />
                        </SocialLogo>
                        <WebSiteRights>
                            {COPYRIGHTS} © {new Date().getFullYear()}{'. Todos os direitos reservados.'} <br />
                        </WebSiteRights>
                        <SocialIcons>
                            <SocialIconLink href={LINK_INSTAGRAM} target="_black" arial-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href={LINK_WHATSAPP} target="_black" arial-label="WhatsApp">
                                <FaWhatsapp />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrapper>
        </FooterContainer>
    )
}

export default Footer;