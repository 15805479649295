import { APP_NAME, optionsMenu } from "../../config";
import { SidebarContainer, CloseIcon, Icon, SidebarWrapper, SidebarLink, SidebarMenu, LogoImg } from "./SidebarElements";
import Logo from '../../images/logo.png';

const Sidebar = (props: any) => {
    return (
        <SidebarContainer open={props.open} onClick={props.toggle}>
            <Icon onClick={props.toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <LogoImg src={Logo} alt={APP_NAME} />
                <SidebarMenu>
                    {
                        optionsMenu.map((option: any, i: number) => {
                            return (<SidebarLink key={i} to={option.to} smooth={true} duration={300} offset={-70} onClick={props.toggle}> {option.label} </SidebarLink>)
                        })
                    }
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar;