export const COLOR_BACKGROUND = '#334166';
export const COLOR_ORANGE = '#f68d2e';
export const COLOR_ORANGE_LIGHT = '#F4B93A';
export const COLOR_BACKGROUND_DARK = '#11111f';
export const COLOR_LIGHT = '#f7f7f7';
export const APP_NAME = 'Exalt';
export const COPYRIGHTS = 'Exalt';
export const LINK_WHATSAPP = 'https://wa.me/5534998218179';
export const LINK_INSTAGRAM = 'https://www.instagram.com/exalt.sd/';

export const optionsMenu = [
    { label: 'Início', to: 'inicio' },
    { label: 'Quem Somos', to: 'quemSomos' },
    { label: 'Serviços', to: 'servicos' },
    { label: 'Como Funciona', to: 'comoFunciona' },
    { label: 'Contato', to: 'contato'}
]
