import styled from 'styled-components';

export const InicioContainer = styled.div`
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 900px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1400px) {
        height: 600px;
    }

    @media screen and (max-width: 864px) {
        height: 400px;
    }
`

export const InicioBg = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 3;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0, 0.2) 0%, rgba(0,0,0,0.6) 100%), linear-gradient(180deg, rgba(0,0,0,0.4) 0%, transparent 100%);
    }

`

export const ImgBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
    z-index: 2;
`

export const InicioContent = styled.div`
    z-index: 4;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const InicioH1 = styled.p`
    font-family: 'Audiowide';
    color: #fff;
    font-size: 46px;
    text-align: center;

    @media screen and (max-width: 864px) {
        font-size: 38px;
    }

    @media screen and (max-width: 600px) {
        font-size: 32px;
    }

    @media screen and (max-width: 480px) {
        font-size: 24px;
    }
`

export const InicioP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    max-width: 700px;

    @media screen and (max-width: 864px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`