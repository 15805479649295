import ImgBackground from '../../images/inicio.jpg'
import {
    InicioContent,
    InicioH1,
    InicioP,
    InicioContainer,
    InicioBg,
    ImgBg
} from "./InicioElements";
import { APP_NAME } from '../../config';

const Inicio = () => {
    return (
        <InicioContainer id='inicio'>
            <InicioBg>
                <ImgBg src={ImgBackground} alt={APP_NAME} />
            </InicioBg>
            <InicioContent>
                <InicioH1>{'Eleve o nível da sua empresa utilizando soluções com as melhores tecnologias do mercado'}</InicioH1>
                <InicioP>{'Seja um site para divulgar a sua marca, uma planilha para organizar seus dados ou um aplicativo para aprimorar seus processos, nós fazemos!'}</InicioP>
            </InicioContent>
        </InicioContainer >
    )
}

export default Inicio;