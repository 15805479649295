import styled from "styled-components";
import { COLOR_ORANGE_LIGHT } from "../../config";

export const IconCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    min-height: 50px;
    min-width: 50px;
    max-height: 50px;
    max-width: 50px;
    border: 2px solid white;
    border-radius: 50%;
    margin-right: 7px;
    transition: 0.7s;
`

export const TitleCard = styled.p`
    font-weight: bold;
    text-align: left;
    font-size: 16px;
    margin-left: 5px;
`

export const ContainerCard = styled.div`
    display: flex;
    flex-direction: flex-start;
    align-items: left;
    margin-bottom: 15px;
    user-select: none;
    
    &:hover {
        cursor: pointer;

        ${IconCard} {
            transform: scale(1.25) rotate(360deg); 
            color: ${COLOR_ORANGE_LIGHT};
            border: 2px solid ${COLOR_ORANGE_LIGHT};
        }

        ${TitleCard} {
            color: ${COLOR_ORANGE_LIGHT};
        }
    }
`;

export const WrapperCard = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`

export const ContentCard = styled.p`
    text-align: left;
    font-size: 16px;
    margin-left: 5px;
`