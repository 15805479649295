import { useEffect } from 'react';
import { Route, Routes, useLocation } from "react-router-dom"
import Home from "../pages/Home"


const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

export const AppRoutes = () => {
    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
            </Routes>
        </>
    );
};