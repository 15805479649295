import { ContainerCard, ContentCard, IconCard, TitleCard, WrapperCard } from "./CardElements"

export const Card = (props: any) => {
    return (
        <div>
            <ContainerCard>
                <IconCard>{props.icon}</IconCard>
                <WrapperCard>
                    <TitleCard>{props.title}</TitleCard>
                    <ContentCard>{props.description}</ContentCard>
                </WrapperCard>
            </ContainerCard>
        </div>
    )
}