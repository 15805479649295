import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';
import { FaTimes } from 'react-icons/fa';
import { COLOR_BACKGROUND_DARK, COLOR_LIGHT, COLOR_ORANGE, COLOR_ORANGE_LIGHT } from '../../config';

export const SidebarContainer = styled.aside<any>`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: ${COLOR_BACKGROUND_DARK};
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ open }) => (open ? '100%' : '0')}; 
    top: ${({ open }) => (open ? '0' : '-100%')};
`

export const CloseIcon = styled(FaTimes)`
    color: ${COLOR_LIGHT};
`

export const Icon = styled.div`
    position: absolute;
    top: 2.5rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`

export const SidebarWrapper = styled.div`
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const LogoImg = styled.img`
    height: 60px;
    margin-bottom: 50px;
`

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 60px);
    text-align: center;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6,60px);
    }
`

export const SidebarLink = styled(LinkS)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #fff;
    font-weight: bold;
    background: linear-gradient(0deg, ${COLOR_ORANGE_LIGHT} 0%, ${COLOR_ORANGE} 35%);
    cursor: pointer;
    border-radius: 8px;
    width: 270px;
    margin: 7px 0;

    &:hover {
        background: ${COLOR_ORANGE};
    }
`