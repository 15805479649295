import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll';
import { COLOR_BACKGROUND_DARK, COLOR_LIGHT } from '../../config';

export const Nav = styled.nav<any>`
    background: ${COLOR_BACKGROUND_DARK};
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 120px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`

export const NavLogo = styled(LinkR)`
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    color: transparent;
    background: linear-gradient(0deg, rgba(255, 130, 46, 1) 0%, rgba(255, 209, 69, 1) 100%);
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
`;

export const NavLogoImg = styled.img`
    margin-right: 10px;
    height: 45px;
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 864px) {
        display: block;
        position: absolute;
        top: 1.5rem;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: ${COLOR_LIGHT};
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 864px) {
        display: none;
    }
`

export const NavItem = styled.li`
    height: 70px;
`

export const NavLinks = styled(LinkS)`
    color: ${COLOR_LIGHT};
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
`

export const NavLinksRouterDom = styled(LinkR)`
    color: ${COLOR_LIGHT};
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
`