import styled from "styled-components";
import { COLOR_ORANGE, COLOR_ORANGE_LIGHT } from "../../config";

export const Button = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    background: linear-gradient(0deg, ${COLOR_ORANGE_LIGHT} 0%, ${COLOR_ORANGE} 35%);
    cursor: pointer;
    border: none;
    border-radius: 8px;
    width: 100%;
    padding: 7px 0;

    &:hover {
        background: ${COLOR_ORANGE};
    }
`