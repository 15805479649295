import styled from "styled-components";
import { COLOR_BACKGROUND_DARK, COLOR_LIGHT, COLOR_ORANGE, COLOR_ORANGE_LIGHT } from "../../config";

export const ApresentacaoContainer = styled.div<any>`
    color: #fff;
    background: ${({ lightbg }) => (lightbg ? COLOR_LIGHT : COLOR_BACKGROUND_DARK)};
    padding: 50px 0;
    user-select: none;
`

export const ApresentacaoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 'auto';
    margin-bottom: auto;
    margin-left: auto;
    justify-content: center;
`

export const ApresentacaoRow = styled.div<any>`
    display: grid;
    grid-auto-columns: minmax()(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({ imgstart }) => (imgstart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 864px) {
        grid-template-areas: ${({ imgstart }) => (imgstart ? `'col1' 'col2'` : `'col2 col2' 'col1 col1'`)};
    }
`

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;

    @media screen and (max-width: 864px) {
        text-align: center;
    }
`

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;

    @media screen and (max-width: 864px) {
        text-align: center;
    }
`

export const TextWrapper = styled.div`
    border-left: 5px solid;
    border-image: linear-gradient(0deg, ${COLOR_ORANGE_LIGHT} 0%, ${COLOR_ORANGE} 50%) 1;
    max-width: 800px;
    padding-left: 15px;
`

export const TopLine = styled.div<any>`
    font-family: 'Audiowide';
    color: ${({ lighttext }) => (lighttext ? '#fff' : COLOR_BACKGROUND_DARK)};
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 1.4px;
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 864px) {
        text-align: center;
        justify-content: center;
    }
`

export const Subtitle = styled.div<any>`
    max-width: 800px;
    font-size: 18px;
    line-height: 22px;
    font-weight: ${({ lighttext }) => (lighttext ? 400 : 600)};
    color: ${({ lighttext }) => (lighttext ? '#f7f8fa' : '#010606')};
`

export const ImgWrap = styled.div`
    max-width: 400px;
    height: 100%;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Img = styled.img`
    max-width: 450px;
    width: 100%;
    margin: 0 0 10px 0;
    
    @media screen and (max-width: 864px) {
        max-width: 0;
        margin: 0;
    }
`
