import { useState } from "react"
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Sobre from '../../components/Sobre';
import SectionBase from '../../components/SectionBase';
import { quemSomos, servicos, comoFunciona, contato } from "../../components/SectionBase/Data";
import Footer from '../../components/Footer';

const Home = () => {
    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    }

    return (
        <>
            <Sidebar open={open} toggle={toggle} />
            <Navbar toggle={toggle} />
            <Sobre />
            <SectionBase {...quemSomos} />
            <SectionBase {...servicos} />
            <SectionBase {...comoFunciona} />
            <SectionBase {...contato} />
            <Footer />
        </>
    )
}

export default Home;