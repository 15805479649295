import { FaBars } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import { MobileIcon, Nav, NavItem, NavLinks, NavLogo, NavMenu, NavbarContainer, NavLogoImg, NavLinksRouterDom } from './NavbarElements';
import Logo from '../../images/logo.png'
import { optionsMenu } from '../../config';

const Navbar = (props: any) => {
    const toggleTop = () => {
        scroll.scrollToTop();
    }

    return (
        <>
            <Nav>
                <NavbarContainer>
                    <NavLogo to='/' onClick={toggleTop}>
                        <NavLogoImg src={Logo} alt='Logo' /> {props.title}
                    </NavLogo>
                    <MobileIcon onClick={props.toggle}> <FaBars /> </MobileIcon>
                    <NavMenu>
                        {
                            props.options ?
                                props.options.map((option: any, i: number) => {
                                    return (
                                        <NavItem key={i}>
                                            <NavLinksRouterDom to={option.to}> {option.label} </NavLinksRouterDom>
                                        </NavItem>
                                    )
                                })
                                :
                                optionsMenu.map((option: any, i: number) => {
                                    return (
                                        <NavItem key={i}>
                                            <NavLinks to={option.to} smooth={true} duration={300} offset={-70}> {option.label} </NavLinks>
                                        </NavItem>
                                    )
                                })
                        }
                    </NavMenu>

                </NavbarContainer>
            </Nav >
        </>
    );
}

export default Navbar;